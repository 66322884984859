import _core2 from "@styled-system/core";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.flexbox = void 0;
var _core = _core2;
var config = {
  alignItems: true,
  alignContent: true,
  justifyItems: true,
  justifyContent: true,
  flexWrap: true,
  flexDirection: true,
  // item
  flex: true,
  flexGrow: true,
  flexShrink: true,
  flexBasis: true,
  justifySelf: true,
  alignSelf: true,
  order: true
};
var flexbox = (0, _core.system)(config);
exports.flexbox = flexbox;
var _default = flexbox;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _flexbox = exports.flexbox;
export { _flexbox as flexbox };